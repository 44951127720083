import BuilderBaseInput from "./BuilderBaseInput";
import BuilderImageInput from "./BuilderImageInput";
import BuilderStringInput from "./BuilderStringInput";

export default class BuilderMediaItemInput extends BuilderBaseInput {
  readonly type: BuilderInput.inputType;
  readonly subFields: any[];
  readonly defaultValue: BuilderInput.MediaItemInputType;

  constructor(readonly name: string, readonly options?: BuilderInput.Options) {
    super(name, options);
    this.type = "object";
    this.subFields = [
      new BuilderStringInput("altText"),
      new BuilderImageInput("mediaItemUrl"),
    ];
    this.defaultValue = {
      altText: "",
      mediaItemUrl: "",
    };
  }
}
