import React from "react";
import { Builder } from "@builder.io/react";

import BuilderStringInput from "@utils/BuilderInputs/BuilderStringInput";
import BuilderLongTextInput from "@utils/BuilderInputs/BuilderLongTextInput";
import FooterEmailCapture, {
  FooterEmailCaptureType,
} from "./FooterEmailCapture";

const EmailCaptureLeadsWrapper: React.FC<FooterEmailCaptureType> = props => (
  <FooterEmailCapture {...props} />
);

export default EmailCaptureLeadsWrapper;

export const RegisterEmailCaptureLeadsWrapper = () => {
  Builder.registerComponent(EmailCaptureLeadsWrapper, {
    name: "email-capture-leads",
    inputs: [
      new BuilderStringInput("headline", { defaultValue: "" }),
      new BuilderStringInput("placeholder", { defaultValue: "" }),
      new BuilderStringInput("buttonText", { defaultValue: "" }),
      new BuilderLongTextInput("disclaimer", { defaultValue: "" }),
    ],
  });
};
