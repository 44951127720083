import * as React from "react";

type SEOMetaPropsType = {
  title: string;
  description: string;
  index: boolean;
};

const SEOMeta: React.FC<SEOMetaPropsType> = ({ title, description, index }) => (
  <>
    <meta
      data-react-helmet="true"
      name="robots"
      content={`max-snippet:-1, max-image-preview:large, max-video-preview:-1, ${
        index && process.env.GATSBY_ENVIRONMENT !== "dev"
          ? "index,follow"
          : "noindex,nofollow"
      }`}
    />
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://spgatsby.wpengine.com/wp-content/uploads/2024/01/Linkedin-image.png"
    />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <title>{title}</title>
  </>
);

export default SEOMeta;
