import React from "react";

import FooterNavigationSection from "../FooterNavigationSection";

type FooterNavigationEssentialType = {
  pathname: string;
};

const FooterNavigationEssential: React.FC<FooterNavigationEssentialType> = ({
  pathname,
}) => (
  <FooterNavigationSection
    pathname={pathname}
    model="footer-navigation-essential"
  />
);

export default FooterNavigationEssential;
