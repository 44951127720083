import BuilderBaseInput from "./BuilderBaseInput";

export default class BuilderImageInput extends BuilderBaseInput {
  readonly type: BuilderInput.inputType;
  readonly allowedFileTypes = ["jpeg", "jpg", "png", "svg"];

  constructor(readonly name: string, readonly options?: BuilderInput.Options) {
    super(name, options);
    this.type = "file";
  }
}
