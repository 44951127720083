import BuilderBaseInput from "./BuilderBaseInput";

export default class BuilderLongTextInput extends BuilderBaseInput {
  readonly type: BuilderInput.inputType;

  constructor(readonly name: string, readonly options?: BuilderInput.Options) {
    super(name, options);
    this.type = "longText";
  }
}
