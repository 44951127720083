import { create } from "zustand";

type UseIsStickyNavStoreType = {
  isSticky: boolean;
  isStickyOpen: boolean;
  setIsSticky: (val: boolean) => void;
  setIsStickyOpen: (val: boolean) => void;
};

const useIsStickyNavStore = create<UseIsStickyNavStoreType>(set => ({
  isSticky: false,
  isStickyOpen: false,
  setIsSticky: val =>
    set(_ => ({
      isSticky: val,
    })),
  setIsStickyOpen: val =>
    set(_ => ({
      isStickyOpen: val,
    })),
}));

export default useIsStickyNavStore;
