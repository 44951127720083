/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { ButtonProps } from "../types/ctaButton";

export function CtaButton({
  link,
  linkUrl,
  copy,
  isDisabled,
  variant,
  copySize,
  noLink,
  hasAnimation,
  openInNewTab = false,
  ...other
}: ButtonProps): JSX.Element {
  const classMap = {
    primarySun: "cta--button_primary-sun",
    primaryForest: "cta--button_primary-forest",
    secondarySun: "cta--button_secondary-sun",
    secondaryForest: "cta--button_secondary-forest",
    tertiarySun: "cta--button_tertiary-sun",
    tertiaryForest: "cta--button_tertiary-forest",
  };

  const sizeMap = {
    bodySmall: "cta--button-small",
    bodyRegular: "cta--button-regular",
    bodyLarge: "cta--button-large",
    bodyExtraLarge: "cta--button-extra-large",
  };

  const classNames = `${sizeMap[copySize] || sizeMap["bodyRegular"]} ${
    classMap[variant] || classMap["primarySun"]
  } ${isDisabled ? "cta--button_disabled" : ""}`;
  const tertiaryClassNames = variant.includes("tertiary") ? "hasTertiary" : "";

  const controls = useAnimation();
  const [animated, setAnimated] = useState(false);

  const showAnimationButton =
    hasAnimation && (variant === "tertiarySun" || variant === "tertiaryForest");
  const onMouseEnter = () => {
    controls
      .start({
        width: 0,
        transition: {
          ease: "linear",
          duration: 0.3,
        },
      })
      .then(() => {
        setAnimated(true);
      });
  };

  const onMouseLeave = () => {
    controls
      .start({
        width: "100%",
        transition: {
          duration: 0.3,
          ease: "easeInOut",
        },
      })
      .then(() => {
        setAnimated(false);
      });
  };

  const Button = () => {
    return (
      <a
        href={(link || linkUrl)!}
        target={!openInNewTab ? "_self" : "_blank"}
        className={`cta--button-wrapper cta--button-font-set ${tertiaryClassNames}`}
        tabIndex={0}
        {...other}
      >
        <div
          className={`cta--button cta--button-font-set ${classNames} ${
            showAnimationButton ? "border-none" : ""
          } justify-center`}
        >
          {copy}
        </div>
      </a>
    );
  };

  return (
    <>
      {showAnimationButton ? (
        <div
          className="relative group cta-container"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Button />
          <motion.div
            className={`absolute right-0 w-full h-[1.5px] ${
              variant === "tertiarySun" ? "bg-sun-600" : "bg-forest-900"
            } ${animated ? "left-0" : "right-0"}`}
            animate={controls}
          />
        </div>
      ) : noLink ? (
        <button
          className={`cta--button-wrapper cta--button-font-set cta-container ${tertiaryClassNames}`}
          type="button"
          {...(other as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        >
          <div
            className={`cta--button cta--button-font-set ${classNames} justify-center`}
          >
            {copy}
          </div>
        </button>
      ) : (
        <Button />
      )}
    </>
  );
}
