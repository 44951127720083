import * as React from "react";
import { HeadProps, PageProps, graphql } from "gatsby";
import { BuilderComponent } from "@builder.io/react";
import SEOMeta from "../../components/SEOMeta";
import { handleContentLoaded } from "../../utils/builderContentLoaded";
import "@builder.io/widgets";
import DefaultLayoutShortNav from "@layouts/DefaultLayoutShortNav";
import { RegisterComponentLibrary } from "@utils/RegisterComponentLibrary";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";
import "../../libs/builder.setting";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const PageCondensedNav: React.FC<PageProps<DataProps>> = ({ data }) => {
  const content = data?.allBuilderModels.onePageCondensedNav?.content;

  const isScrollable = content?.data?.useScroll;
  enableSmoothScroll(isScrollable);

  return (
    <DefaultLayoutShortNav
      hasFooterLargeCta={false}
      alter={content?.data?.whiteLogo}
      isFixed
      isTracked
      hasNoFooterLinks
      hasNoHeader={false}
      globalFooterLargeH={{
        navCtaText: content?.data?.navCtaText || "Start for free",
        navCtaLink: content?.data?.navCtaLink || "/sign-up/",
      }}
      builderComponent
    >
      {content && (
        <BuilderComponent
          content={content}
          model="page-condensed-nav"
          contentLoaded={handleContentLoaded}
        />
      )}
    </DefaultLayoutShortNav>
  );
};

RegisterComponentLibrary();

export default PageCondensedNav;

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { onePageCondensedNav },
  } = data;
  const seoData = onePageCondensedNav?.content
    ?.data as Queries.builder_PageCondensedNavData;

  return (
    <>
      <SEOMeta
        index={seoData?.index!}
        title={seoData?.title!}
        description={seoData?.description!}
      />
      {seoData?.json && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              ...seoData.json,
            }),
          }}
        />
      )}
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePageCondensedNav(
        target: { urlPath: $path }
        options: { cachebust: false }
      ) {
        content
      }
    }
  }
`;
