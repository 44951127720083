import React from "react";
import { AnimationControls, motion, useAnimation } from "framer-motion";

type UnderlineAnimationProps = {
  children: React.ReactNode;
  className?: string;
  extraControls?: AnimationControls;
  direction?: string;
};

export const startUnderlineAnimation = {
  width: ["0%", "100%"],
  transition: {
    duration: 0.3,
    ease: "easeInOut",
  },
};

export const stopUnderlineAnimation = {
  width: 0,
  transition: {
    ease: "linear",
    duration: 0.3,
  },
};

export const startReverseUnderlineAnimation = {
  width: ["100%", "0%"],
  transition: {
    ease: "linear",
    duration: 0.3,
  },
};

export const stopReverseUnderlineAnimation = {
  width: "100%",
  transition: {
    duration: 0.3,
    ease: "easeInOut",
  },
};

const UnderlineAnimation: React.FC<UnderlineAnimationProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  direction = "forwards",
  // eslint-disable-next-line react/prop-types
  extraControls,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const controls = extraControls || useAnimation();

  const onMouseEnter = () => {
    if (direction === "forwards") {
      controls.start(startUnderlineAnimation);
    } else {
      controls.start(startReverseUnderlineAnimation);
    }
  };

  const onMouseLeave = () => {
    if (direction === "forwards") {
      controls.start(stopUnderlineAnimation);
    } else {
      controls.start(stopReverseUnderlineAnimation);
    }
  };

  return (
    <>
      <div
        className={`relative group ${
          direction === "forwards" ? "" : "inline-block"
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
        <motion.div
          className={`absolute group-hover:w-full h-[1.5px] bg-forest-900 ${className} ${
            direction === "forwards"
              ? "w-0 group-hover:left-0 right-0"
              : "w-full group-hover:w-full group-hover:right-0"
          }`}
          animate={controls}
          initial={{ width: `${direction === "forwards" ? "0%" : "100%"}` }}
        />
      </div>
    </>
  );
};

export default UnderlineAnimation;
