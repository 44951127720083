import type { BuilderComponent } from "@builder.io/react";
import { fireMxpEvent } from "./mixpanelEvents";

type ExperimentDataType = {
  experimentName: string;
  page: string;
  variation: string;
};

const trackExperiment = (expData: ExperimentDataType) => {
  const retryLimit = 20;
  let retryCounter = 0;
  const analyticsInterval = setInterval(() => {
    if (window.analytics) {
      window.analytics?.track("Experiment Started", {
        url: window.location.pathname,
        initiator: "User",
        object: "Experiment",
        action: "Started",
        source_application: "Marketing",
        experiment_name: expData.experimentName,
        variant: expData.variation,
      });
      clearInterval(analyticsInterval);
      return;
    }
    if (retryCounter >= retryLimit) clearInterval(analyticsInterval);
    retryCounter++;
  }, 100);
};

export default function onBuilderExperimentStarted(
  content: Parameters<BuilderComponent["onContentLoaded"]>[1]
) {
  if (typeof content !== "object") return;

  const isExperiment = "data" in content && content.data!.useExperiment;

  if (isExperiment) {
    const { data } = content;

    const expData = {
      experimentName: data!.experimentName,
      page: data?.url || window.location.pathname,
      variation: data!.variantName,
    };

    fireMxpEvent("user: experiment started", expData);
    trackExperiment(expData);
  }
}
