import { BuilderComponent } from "@builder.io/react";
import onBuilderExperimentStarted from "./mixpanelEvents/onBuilderExperimentStarted";
import onBuilderDropCookie from "./onBuilderDropCookie/onBuilderDropCookie";

export const handleContentLoaded: BuilderComponent["onContentLoaded"] = (
  _,
  content
) => {
  onBuilderExperimentStarted(content);
  onBuilderDropCookie(content);
};
