import React, { useEffect, useState } from "react";

import { useMainContext } from "../../../contexts/MainContext";
import useShowNavStore from "../../../sp-state/useShowNavStore";
import useIsStickyNavStore from "../../../sp-state/useIsStickyNavStore";
import usePageOffset from "../../../hooks/usePageOffset";

import styles from "./Header.module.scss";

/* TODO
  Create a new header that holds pricing page logic, see line 39
*/

type HeaderProps = {
  children: React.ReactNode;
  className?: string;
  noSticky?: MainLayoutTypes.noSticky;
};

const Header: React.FC<
  React.DetailedHTMLProps<
    React.AllHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    HeaderProps
> = ({ children, className, noSticky = false, ...rest }) => {
  const { hasHeaderShadow } = useMainContext();
  const { y: pageOffSetY } = usePageOffset();
  const setIsSticky = useIsStickyNavStore(state => state.setIsSticky);
  const showNav = useShowNavStore(state => state.showNav);

  const HEADER_STYLES = `${styles.header}`;
  const [headerStyles, setHeaderStyles] = useState(HEADER_STYLES);

  useEffect(() => {
    if (!noSticky && pageOffSetY > 200) {
      setBoxShadowForPricing();
      setIsSticky(true);
    } else {
      setHeaderStyles(HEADER_STYLES);
      setIsSticky(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffSetY, noSticky, showNav, hasHeaderShadow]);

  const setBoxShadowForPricing = () => {
    const withShadow = hasHeaderShadow
      ? `${HEADER_STYLES} ${styles.isSticky}`
      : `${HEADER_STYLES} ${styles.isSticky} ${styles.noBoxShadow}`;

    setHeaderStyles(withShadow);
  };

  const headerClasses = `${headerStyles}`;

  return (
    <div
      data-testid="header-wrapper"
      className={`${styles.headerWrapper} ${className}`}
      {...rest}
    >
      <header data-testid="header" className={headerClasses}>
        <div className={styles.headerContent}>{children}</div>
      </header>
    </div>
  );
};

export default Header;
