import React, { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";

type FooterNavigationEssentialType = {
  pathname: string;
  model: string;
};

export const FooterNavigationSection: React.FC<FooterNavigationEssentialType> =
  ({ model = "", pathname }) => {
    const [builderContent, setBuilderContent] = useState(null);
    const isPreviewing = useIsPreviewing();

    useEffect(() => {
      builder
        .get(model, {
          url: pathname,
          options: { cachebust: false },
        })
        .promise()
        .then(modelData => {
          if (modelData || isPreviewing) {
            setBuilderContent(modelData);
            return;
          }
        });
    }, [pathname, model, isPreviewing]);

    return builderContent ? (
      <BuilderComponent content={builderContent!} model={model} />
    ) : (
      <></>
    );
  };

export default FooterNavigationSection;
