import { useEffect } from "react";
import { getPageCount } from "../utils/getPageCount";

const useGtmPageChange = (eventId: string) => {
  useEffect(() => {
    if (getPageCount() > 0) {
      setTimeout(() => {
        if (window.dataLayer)
          window.dataLayer.push({ event: "pageChange", spEventId: eventId });
      }, 100);
    }
  }, []);
};

export default useGtmPageChange;
