import React from "react";
import { Link } from "gatsby";

import Header from "../Header";
import usePageOffset from "../../../hooks/usePageOffset";
import useWindowSize from "../../../hooks/useWindowSize";

import styles from "./HeaderWithCtaOnly.module.scss";
import SignUpNavDropdown from "@components/Dropdowns/SignUpNavDropdown";
import { segmentTrack } from "@utils/segment/segment-track";

type HeaderWithCtaOnlyType = {
  alter?: MainLayoutTypes.alter;
  ctaLink: string;
  ctaText: string;
  noSticky?: MainLayoutTypes.noSticky;
  isFixed?: boolean;
  builderComponent?: boolean;
};

const HeaderWithCtaOnly: React.FC<
  React.DetailedHTMLProps<
    React.AllHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
    HeaderWithCtaOnlyType
> = ({
  alter,
  ctaLink,
  ctaText,
  isFixed,
  noSticky,
  builderComponent,
  ...rest
}) => {
  const { width: windowWidth } = useWindowSize();
  const { y: pageOffSetY } = usePageOffset();
  const logoPath = typeof window !== "undefined" && window.location.href;
  const isSticky = pageOffSetY < 200;

  const isWhiteLogo = builderComponent
    ? isSticky
    : windowWidth > 1023 && isSticky;

  const logoStyles = alter && isWhiteLogo ? "sp-logo-white" : "sp-logo-forest";

  const headerStyles = `${styles.headerWithCtaOnly} ${
    isFixed && styles.isFixed
  }`;

  const customSegmentEvent = () => {
    const pathname = window.location.pathname;

    segmentTrack("Marketing CTA User Differentiation Clicked", {
      action: "Clicked",
      initiator: "User",
      object: "Market CTA User Differentiation",
      source_application: "Marketing",
      target: pathname,
      type_selected: "Practitioner",
      url: pathname,
    });
  };

  return (
    <Header className={headerStyles} noSticky={noSticky} {...rest}>
      <Link to={logoPath || ""} className={`${styles.logo}`}>
        <svg className={styles.logoSvg}>
          <use href={`/icons/sp-logo.svg#${logoStyles}`}></use>
        </svg>
      </Link>

      <SignUpNavDropdown
        label={ctaText}
        customSegmentEvent={customSegmentEvent}
      />
    </Header>
  );
};

export default HeaderWithCtaOnly;
