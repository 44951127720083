export default class BuilderBaseInput {
  readonly enum?: string[];
  readonly defaultValue?: {};
  readonly friendlyName?: string;
  readonly helperText?: string;
  readonly subFields?: any[];
  readonly type: BuilderInput.inputType;

  constructor(readonly name: string, readonly options?: BuilderInput.Options) {
    this.type = "string";
    this.defaultValue = options?.defaultValue;
    this.enum = options?.enum || [];
    this.friendlyName = options?.friendlyName;
    this.helperText = options?.helperText;
    this.subFields = options?.subFields || [];
  }
}
