import React, { useEffect, useState } from "react";
import { GlobalFooterLargeCtaHType } from "../../assets/Types/AcfGroupTypes/GlobalFooterLargeCtaHType.type";
import { GlobalFooterLargeCtaO } from "@types";
import { MainProvider } from "../../contexts/MainContext";
import { mxpPageViewed } from "../../utils/mixpanelEvents/mixpanelEvents";
import GlobalFooterLargeCta from "../../components/CTAs/GlobalFooterLargeCta/GlobalFooterLargeCta";
import HeaderWithCtaOnly from "../../components/Headers/HeaderWithCtaOnly";
import useGtmPageChange from "../../hooks/useGtmPageChange";
import generateUuId from "../../utils/generateUuId";
import FooterNavigationEssential from "@containers/builderSectionModels/FooternavigationEssential";

declare global {
  interface Window {
    mixpanelTrialSent: boolean;
    trackMixpanelEvent: (
      event: string,
      data?: {
        [key: string]: any;
      }
    ) => void;
    grecaptcha: any;
    dataLayer: {
      push: (someObj: Object) => boolean;
    };
    gtag: any;
    fbq: any;
    google_conversion_first_name: string;
    google_conversion_last_name: string;
    google_conversion_email: string;
    google_conversion_phone_number: string;
    Grnhse: {
      Iframe: {
        load: (jobId?: number) => void;
      };
    };
    growsumo?: {
      cookie_domain: string;
      domain_v1: string;
      domain_v2: string;
      fallback_domain: string;
      initialized: boolean;
      data: {
        amount: number;
        currency: string;
        customer_key: string;
        email: string;
        host_domain: string;
        name: string;
        public_key: string;
      };
      createSignup: () => void;
    };
    Osano: {
      cm: {
        showDrawer: (s: string) => void;
        showDoNotSell: () => void;
      };
    };
  }
}

type ShortNavType = {
  alter?: MainLayoutTypes.alter;
  children: React.ReactNode;
  FooterLargeCtaData?: GlobalFooterLargeCtaO;
  globalFooterLargeH?: GlobalFooterLargeCtaHType;
  hasFooterLargeCta?: boolean;
  hasNoDivider?: boolean;
  hasNoFooter?: boolean;
  hasNoFooterLinks?: boolean;
  hasNoHeader?: boolean;
  headerType?: string;
  isFixed?: boolean;
  isTracked?: boolean;
  noStickyHeader?: MainLayoutTypes.noSticky;
  page?: MainLayoutTypes.PageVariantType;
  smartDisplayToggle?: string;
  pathname: string;
  builderComponent?: boolean;
};

const DefaultLayoutShortNav: React.FC<ShortNavType> = ({
  alter,
  children,
  FooterLargeCtaData,
  globalFooterLargeH,
  hasFooterLargeCta,
  hasNoFooter,
  hasNoHeader,
  isFixed,
  isTracked,
  noStickyHeader,
  pathname,
  builderComponent,
}) => {
  const uuid = generateUuId();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);

    if (isTracked) {
      mxpPageViewed();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useGtmPageChange(uuid);

  return (
    <MainProvider>
      <React.Fragment key={`layout-${isClient}`}>
        {!hasNoHeader && (
          <HeaderWithCtaOnly
            alter={alter}
            ctaLink={globalFooterLargeH?.navCtaLink!}
            ctaText={globalFooterLargeH?.navCtaText!}
            data-testid="header-with-cta-only"
            isFixed={isFixed}
            noSticky={noStickyHeader}
            builderComponent={builderComponent}
          />
        )}

        <div>
          <main>
            {children}
            {hasFooterLargeCta && (
              <GlobalFooterLargeCta
                LargeCtaData={
                  globalFooterLargeH &&
                  globalFooterLargeH.smartDisplayToggle === "on"
                    ? globalFooterLargeH
                    : FooterLargeCtaData
                }
              />
            )}
          </main>
        </div>

        {!hasNoFooter && <FooterNavigationEssential pathname={pathname} />}
      </React.Fragment>
    </MainProvider>
  );
};

export default DefaultLayoutShortNav;
