import React from "react";
import { Builder } from "@builder.io/react";

import BuilderStringInput from "@utils/BuilderInputs/BuilderStringInput";
import BuilderMediaItemInput from "@utils/BuilderInputs/BuilderMediaItemInput";
import SocialList, { SocialLinksType } from "./SocialList/SocialList";

const SocialLinksWrapper: React.FC<SocialLinksType> = ({ socialLinks }) => (
  <SocialList socialLinks={socialLinks} />
);

export default SocialLinksWrapper;

export const RegisterSocialLinksWrapper = () => {
  Builder.registerComponent(SocialLinksWrapper, {
    name: "social-links",
    inputs: [
      {
        name: "socialLinks",
        type: "list",
        subFields: [
          new BuilderStringInput("link", { defaultValue: "" }),
          new BuilderMediaItemInput("image"),
        ],
      },
    ],
  });
};
