import React, { ElementRef, useRef } from "react";

import { mxpPrimaryCtaClicked } from "@utils/mixpanelEvents/mixpanelEvents";
import ClientPractitionerDropdownMenu from "../ClientPractitionerDropdownMenu";
import Dropdown from "../Dropdown";
import useIsStickyNavStore from "@sp-state/useIsStickyNavStore";

import styles from "./SignUpNavDropdown.module.scss";
import { marketingCtaClicked } from "@utils/segment/marketingCtaClicked";

type SignUpNavDropdownType = {
  label: string;
  className?: string;
  customSegmentEvent?: () => void;
  isRebrand?: boolean;
};

const SignUpNavDropdown: React.FC<SignUpNavDropdownType> = ({
  label,
  className,
  customSegmentEvent,
  isRebrand,
}) => {
  const isSticky = useIsStickyNavStore(state => state.isSticky);
  const buttonRef = useRef<ElementRef<"button">>(null);

  const handleClick = () => {
    mxpPrimaryCtaClicked(null!, label);

    if (customSegmentEvent) {
      return customSegmentEvent();
    }

    marketingCtaClicked({
      text: label,
      url: window.location.pathname,
    });
  };

  return (
    <Dropdown
      className={`${styles.dropdown} ${
        isSticky && styles.showCtaOnSticky
      } ${className}`}
    >
      <Dropdown.Button
        analyticsClickEvents={handleClick}
        className={`${styles.dropdownCta} ${
          isRebrand && styles.dropdownCtaRebrand
        }`}
        isNavItem
        ref={buttonRef}
      >
        {label}
      </Dropdown.Button>

      <ClientPractitionerDropdownMenu
        className={styles.dropdownMenu}
        clientIntent={"sign_up"}
        isRebrand={isRebrand}
      />
    </Dropdown>
  );
};

export default SignUpNavDropdown;
