import React from "react";

import styles from "./SocialList.module.scss";

type SocialLinkType = {
  link: string;
  image: {
    altText: string;
    mediaItemUrl: string;
  };
};

export type SocialLinksType = {
  socialLinks: Array<SocialLinkType>;
};

const SocialList: React.FC<SocialLinksType> = ({ socialLinks }) => {
  const socialLinksList =
    socialLinks &&
    socialLinks.map((socialLink, idx) => (
      <a
        key={`${Date.now()}-${idx}`}
        href={socialLink?.link}
        className={`${styles.buttonIcon}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt={socialLink?.image?.altText}
          className={styles.icon}
          src={socialLink?.image?.mediaItemUrl}
        />
      </a>
    ));

  return (
    <div className={styles.socialList} data-testid="social-links-list">
      {socialLinksList}
    </div>
  );
};

export default SocialList;
